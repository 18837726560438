<template>
	<div class="app-container">
		<div class="filter-container">
			<div class="filter-item">
				<button-permissions :datas="'navigationAdd'">
					<el-button type="primary" style="width:100px;" @click="add">添加</el-button>
				</button-permissions>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="navigationList" style="width: 100%;margin-bottom: 20px;" row-key="id" border>
				<el-table-column prop="navigationName" label="导航分类名称" width="320"></el-table-column>
				<el-table-column prop="creatorName" label="创建人"></el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>

				<el-table-column prop="address" label="操作" width="320">
					<template slot-scope="scop">
						<div style="display:flex;">
							<button-permissions :datas="'navigationEdit'">
								<div class="table-button" @click="edit(scop.row)">编辑</div>
							</button-permissions>
							<button-permissions :datas="'navigationDel'">
								<div class="table-button" style="color:#F56C6C" @click="deleteItem(scop.row.id)">删除</div>
							</button-permissions>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 新增/编辑弹框 -->
		<el-dialog :visible.sync="jurVisable" width="50%" :title="jurTitle" :show-close="false">
			<div style="display: flex;border-top: 1px solid #f5f5f5;padding-top: 20px;">
				<el-form
					:model="ruleForm"
					ref="ruleForm"
					label-width="100px"
					class="demo-ruleForm"
					:validate-on-rule-change="false"
				>
					<el-form-item
						label="导航分类:"
						prop="navigationName"
					>
						<el-input
							v-model="ruleForm.navigationName"
							placeholder="最多输入6个字"
							maxlength="6"
							clearable
							style="width:300px"
						></el-input>
					</el-form-item>
					<!-- <el-form-item
						label="关联分组:"
						prop="relationGroupId"
					>
						<el-select
							v-model="ruleForm.relationGroupId"
							placeholder="请选择分组"
							style="width: 300px;margin-right: 10px;"
							filterable
							clearable
						>
							<el-option
								v-for="item in productSortList"
								:key="item.groupId"
								:label="item.groupName"
								:value="item.groupId"
							></el-option>
						</el-select>
					</el-form-item> -->
                    <el-form-item
						label="选择链接:"
						prop="relationParams"
					>
						<div class="link-modal-info" @click="showModal()">
                            <div class="link-info" v-if="!ruleForm.relationParams.data">
                                <div class="cont" style="background: transparent;color: #409eff;">请选择页面</div>
                                <div class="icon" style="background: transparent;">
                                    <i class="el-icon-arrow-down" style="color: #409eff;font-size: 16px;background: transparent;"></i>
                                </div>
                            </div>
                            <div class="link-info" v-else>
                                <div class="cont">{{ruleForm.relationParams.text}}</div>
                                <div class="icon flex center">
                                    <i class="el-icon-arrow-down" style="color: #fff;font-size: 16px;"></i>
                                    <div style="margin-left: 10px;font-size:14px" @click.stop="ruleForm.relationParams = {}">
                                        x
                                    </div>
                                </div>
                                
                            </div>
                        </div>
					</el-form-item>
					<el-form-item
						label="图片："
						:inline-message="true"
						prop="navigationImgUrl"
						:rules="[{required:true,message:'请关上传图片',trigger: 'blur'}]"
					>
						<el-upload
							class="avatar-uploader"
							:action="imgApi"
							:show-file-list="false"
							:on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload"
							style="width:80px;height:80px"
						>
							<img
								v-if="ruleForm.navigationImgUrl"
								:src="ruleForm.navigationImgUrl+'?x-oss-process=image/resize,m_fixed,h_80,w_80'"
								style="width:80px;height:80px"
								class="avatar"
							/>
							<i
								v-else
								class="el-icon-plus"
								style="width:78px;height:48px;font-size: 20px;color:#999999;margin-top:30px;"
							></i>
						</el-upload>
					</el-form-item>
				</el-form>
			</div>
			<!-- 按钮区域 -->
			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="jurVisable=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;" @click="saveChange()" :loading="loading">保存</el-button>
			</div>
		</el-dialog>

        <!-- 选择链接弹窗 -->
        <link-modal 
            :showLiveTab="true" 
            :visible="shopVisible" 
            @change="dataChange" 
            @cancel="shopVisible=false">
        </link-modal>
	</div>
</template>

<script>
import {
	getnNavigationList,
	navigationDel,
	navigationAdd,
	navigationEdit
} from '@/api/TurnTomySelf.js'
import {
	productGroupListData
} from '@/api/goods'
import buttonPermissions from '@/components/buttonPermissions';
import config from '@/config/index'
import linkModal from '@/views/components/linkModal';
export default {
	components: {
        buttonPermissions,
        linkModal
	},
	data () {
		return {
			navigationList: [],  //广告位置列表数据
			ruleForm: {
				id: '',
				navigationName: '',   //分类名称
				relationGroupId: '',  //关联分组ID
                navigationImgUrl: '',      //图片
                relationParams:{},    //选择链接
			},
			jurTitle: '',    //弹窗名称
			jurVisable: false,   //弹窗开关
			loading: false,  //加载
			productSortList: [],  //类目列表数据
            imgApi: config.UPLOAD_IMG,
            shopVisible:false,  //链接弹窗开关
		};
	},
	created () {
		this.getInfor();
		this.getnNavigationList();
	},
	methods: {
		//获取分类数据
		async getInfor () {
			try {
				let result = await productGroupListData({ pageNo: 0, pageSize: 10000 })
				this.productSortList = result.data.records;
			} catch (e) {
				console.log(e)
			}
		},
		//获取导航列表
		async getnNavigationList () {
			let data = {
				pageNo: this.pageNo,
				pageSize: this.pageSize,
			}

			let res = await getnNavigationList(data);
			this.navigationList = res.data;
			this.total = res.data.total;
		},
		//添加
		add () {
			this.jurTitle = "新增菜单";
			this.jurVisable = true;
			this.ruleForm.navigationName = '';
			this.ruleForm.relationGroupId = '';
			this.ruleForm.navigationImgUrl = '';
			this.ruleForm.relationParams = {};
			this.ruleForm.id = '';
		},
		//编辑
		edit (item) {
			console.log(item);
			this.jurTitle = "编辑菜单";
			this.ruleForm.navigationName = item.navigationName;
			this.ruleForm.relationGroupId = item.relationGroupId;
			this.ruleForm.id = item.id;
            this.ruleForm.navigationImgUrl = item.navigationImgUrl;
            try {
                this.ruleForm.relationParams = JSON.parse(item.relationParams) ? JSON.parse(item.relationParams) : {};
            } catch (error) {
                this.ruleForm.relationParams = {};
            }
            
			this.jurVisable = true;
		},
		// 上传规格图成功钩子
		handleAvatarSuccess (res) {
			this.ruleForm.navigationImgUrl = res.data[0];
			return true;
		},
		// 上传规格图前钩子
		beforeAvatarUpload (file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
			const isLt3M = file.size / 1024 / 1024 < 3;
			if (!isJPG) {
				this.$message.error('上传头像图片必须是 JPG 或者PNG格式!');
			}
			if (!isLt3M) {
				this.$message.error('图片大小请控制在3M以内!');
			}
			return isJPG && isLt3M;
		},
		//删除
		deleteItem (id) {
			console.log(id);
			this.$confirm('删除后将无法恢复, 是否继续?', '删除菜单', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				let result = await navigationDel({
					id: id
				});
				if (result.success) {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
				} else {
					this.$message({
						type: 'error',
						message: result.alertMsg
					});
				}
				this.getnNavigationList();
			}).catch(() => {
				console.log('取消删除');
			});
		},
		//弹窗确定按钮
		async saveChange () {
            var _this = this;
			this.$refs['ruleForm'].validate(async (valid) => {
				if (valid) {
					try {
						this.loading = true;

                        let result = {};
                        let params = JSON.parse(JSON.stringify(_this.ruleForm));
                        params.relationParams = JSON.stringify(params.relationParams);
						if (_this.ruleForm.id) {
							result = await navigationEdit(params);
						} else {
							result = await navigationAdd(params);
						}

						if (result.success) {
							_this.$message({
								showClose: true,
								type: 'success',
								message: '操作成功!'
							});
							_this.jurVisable = false;
							_this.getnNavigationList();
						} else {
							_this.$message({
								showClose: true,
								type: 'error',
								message: result.alertMsg
							});
						}
						this.loading = false
					} catch (error) {
						console.log(error);
						this.loading = false
					}
				} else {
					return false;
				}
			});
        },
        
        //链接弹窗
        showModal(){
            this.shopVisible = true;
        },
        //链接变更
        dataChange(record){
            this.ruleForm.relationParams = record;
        },
	},
};
</script>

<style lang="less" scoped>
.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}
/deep/.el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

/deep/.el-upload:hover {
	border-color: #409eff;
}
</style>